export const getDeviceType = () => {
    const userAgent = navigator.userAgent;
  
    // Check if the user agent string contains specific keywords for different devices
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
  
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
  
    // Check the viewport width using window.matchMedia()
    if (window.matchMedia('(max-width: 768px)').matches) {
      return 'Mobile';
    }
  
    // Default to 'Desktop' if none of the above conditions match
    return 'Desktop';
  };
  