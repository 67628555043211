export const timeValidationRules = [{ value: "", label: "" }];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 30) {
    let hour = i < 10 ? `0${i}` : `${i}`;
    let minute = j === 0 ? '00' : `${j}`;
    let time = `${hour}:${minute}`;
    let period = i < 12 ? 'AM' : 'PM';
    let displayHour = i === 0 ? 12 : (i > 12 ? i - 12 : i);
    displayHour = displayHour < 10 ? `0${displayHour}` : `${displayHour}`;
    let displayTime = `${displayHour}:${minute} ${period}`;
    timeValidationRules.push({ value: time, label: displayTime });
  }
}
