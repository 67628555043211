const FormSelect = ({ id, label, value, onChange, options, required=true }) => {
  return (
    <div>
      <label htmlFor={id}>{label}:</label>
      <select
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        style={{ width: '100px', marginLeft: '8px', marginBottom: '10px' }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormSelect;
