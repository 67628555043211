import { timeValidationRules } from './timeValidationRules';

export function getNearestHalfHour() {
  const coeff = 1000 * 60 * 30;
  const date = new Date();
  const currentTime = date.getTime();
  const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);

  if (rounded.getTime() === currentTime) {
    // If the current time is exactly on a half hour, increment to the next half hour
    rounded.setTime(rounded.getTime() + coeff);
  }

  let hour = rounded.getHours();
  let minute = rounded.getMinutes() === 0 ? '00' : rounded.getMinutes();
  hour = hour < 10 ? `0${hour}` : `${hour}`;

  return `${hour}:${minute}`;
}

export function getDefaultStartTime() {
  return findTimeInArray(getNearestHalfHour()).value;
}

export function getDefaultEndTime(startTime) {
  const startTimeIndex = timeValidationRules.findIndex(obj => obj.value === startTime);
  const nextTimeIndex = startTimeIndex + 2;
  return nextTimeIndex < timeValidationRules.length ? timeValidationRules[nextTimeIndex].value : timeValidationRules[timeValidationRules.length - 1].value;
}

function findTimeInArray(time) {
  return timeValidationRules.find(obj => obj.value === time);
}
