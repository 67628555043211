import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Welcome to gather</h1>
        <p>host your next community event</p>
        <Link to="/event-form">
          <button>Create Event</button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
