const FormInput = ({ id, label, type="text", value, onChange, placeholder, required=true }) => {
  return (
    <div>
      <label htmlFor={id}>{label}:</label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        style={{ width: '300px', marginBottom: '10px' }}
      />
    </div>
  );
};

export default FormInput;
