import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SubmissionConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const eventDetails = location.state?.eventDetails;

  // Date formatting
  let formattedDate = '';
  if (eventDetails && eventDetails.event_date) {
    const eventDate = new Date(eventDetails.event_date);
    formattedDate = eventDate.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  const onReturnToForm = () => {
    navigate('/event-form');
  };

  if (!eventDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '100vh',
      width: '100%',
      backgroundColor: '#f8f8f8', // Light grey background
    }}>
      <div style={{
        maxWidth: '600px',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Light box shadow for a "card" look
      }}>
        <h1>Thank you for your submission!</h1>
        <h2>Event Details</h2>
        <p>Name: {eventDetails.event_name}</p>
        <p>Location: {eventDetails.event_location}</p>
        <p>Date: {formattedDate}</p>  
        <p>Start Time: {eventDetails.event_start_time}</p>
        <p>End Time: {eventDetails.event_end_time}</p>

        <button style={{
          marginTop: '20px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007BFF',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
        onClick={onReturnToForm}>Return to Event Form</button>
      </div>
    </div>
  );
};

export default SubmissionConfirmation;
