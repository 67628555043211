import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import EventForm from "./pages/EventForm";
import SubmissionConfirmation from './pages/SubmissionConfirmation';
import Navbar from "./components/Navbar";

function App() {
  return (
    <>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/event-form" element={<EventForm />} />
          <Route path="/confirmation" element={<SubmissionConfirmation />} />
        </Routes>
      </div>
    </>
  )
}

export default App;
