import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { timeValidationRules } from '../timeValidationRules';
import FormInput from '../components/FormInput';
import FormSelect from '../components/FormSelect';
import { getDefaultStartTime, getDefaultEndTime } from '../defaultTimeUtils';
import { getDeviceType } from '../deviceUtils';
import { useNavigate } from 'react-router-dom';

const EventForm = () => {
  const [eventName, setEventName] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventStartTime, setEventStartTime] = useState(getDefaultStartTime());
  const [eventEndTime, setEventEndTime] = useState(getDefaultEndTime(eventStartTime));
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const deviceType = getDeviceType();
    const newEvent = {
      event_name: eventName,
      event_location: eventLocation,
      event_date: eventDate,
      event_start_time: eventStartTime,
      event_end_time: eventEndTime,
      created_at: new Date(),
      device_type: deviceType,
    };

    try {
      const { data, error } = await supabase
        .from('events')
        .insert([newEvent]);

      if (error) {
        console.error('Error adding event:', error);
      } else {
        setEventName('');
        setEventLocation('');
        setEventDate('');
        setEventStartTime('');
        setEventEndTime('');


        // Navigate to the confirmation page:
        navigate('/confirmation', { state: { eventDetails: newEvent } });
      }
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  const handleTimeChange = (e) => {
    setEventStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEventEndTime(e.target.value);
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f8f8', // Light grey background
    }}>
      <form 
        onSubmit={handleSubmit} 
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          maxWidth: '600px',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Light box shadow for a "card" look
        }}
      >
        <FormInput 
          id="eventName"
          label="Event Name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          placeholder="Event Name"
          required={true}
        />
        <FormInput 
          id="eventLocation"
          label="Event Location"
          value={eventLocation}
          onChange={(e) => setEventLocation(e.target.value)}
          placeholder="Event Location"
          required={false}
        />
        <FormInput 
          type="date"
          id="eventDate"
          label="Event Date"
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
          placeholder="Event Date"
          required={true}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormSelect 
            id="eventStartTime"
            label="Start Time"
            value={eventStartTime}
            onChange={handleTimeChange}
            options={timeValidationRules}
            required={true}
          />
          <FormSelect 
            id="eventEndTime"
            label="End Time"
            value={eventEndTime}
            onChange={handleEndTimeChange}
            options={timeValidationRules}
            required={true}
        />
      </div>
      <button 
          type="submit" 
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EventForm;
